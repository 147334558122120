import { useEffect, useState } from "react";
import { Recommendation } from "../../../services/models/recommendation";
import * as RecommendationApi from "../../../services/network/recommendation-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import RecommendationCard from "./RecommendationCard";

const RecommendationsPageView = () => {
    const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
    const [recommendationsLoading, setRecommendationsLoading] = useState(true);
    const [showRecommendationsLoadingError, setShowRecommendationsLoadingError] = useState(false);

    useEffect(() => {
        async function loadRecommendations() {
            try {
                setShowRecommendationsLoadingError(false);
                setRecommendationsLoading(true);
                const recommendationsData = await RecommendationApi.fetchRecommendations();
                setRecommendations(recommendationsData);
            } catch (error) {
                console.error(error);
                setShowRecommendationsLoadingError(true);
            } finally {
                setRecommendationsLoading(false);
            }
        }
        loadRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToComments = (recommendation: Recommendation) => {
        let path = `${recommendation.id}`;
        navigate(path);
    }

    let navigate = useNavigate();

    const SectorsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                recommendations.sort((a, b) => a.name.localeCompare(b.name)).map(recommendation => (
                    <Col key={recommendation.id}>
                        <RecommendationCard 
                            recommendation={recommendation}
                            onRecommendationClicked={goToComments}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Рекомендации</h1>
            {recommendationsLoading && <Spinner animation='border' variant='primary' />}
            {showRecommendationsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!recommendationsLoading && !showRecommendationsLoadingError && 
                <>
                    {
                        recommendations.length > 0
                        ? SectorsGrid
                        : <p>Вам ещё не добавили рекомендации</p>
                    }
                </>
            }
        </>
    );
};

export default RecommendationsPageView;