import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import styleUtils from "../../../assets/styles/utils.module.css";
import { Recommendation } from "../../../services/models/recommendation";

interface SectorCardProps {
    recommendation: Recommendation,
    onRecommendationClicked: (recommendation: Recommendation) => void,
    className?: string,
}

const RecommendationCard = ({recommendation, onRecommendationClicked, className}: SectorCardProps) => {
    const { name } = recommendation;
    return(
        <Card
        className={`${styles.projectCard} ${className}`}
        onClick={(e) => {
            e.preventDefault()
            onRecommendationClicked(recommendation)
        }}
        >
        <Card.Body className={styles.cardBody}>
            <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                {name}
            </Card.Title>
        </Card.Body>
    </Card>
    );
}

export default RecommendationCard