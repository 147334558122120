import { Card } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { PollTag as PollTagModel } from "../services/models/poll_tag";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";
import { PollTemplate as PollTemplateModel } from "../services/models/poll_template";
import translateDate from "../Utils/translateDate";
import { Poll as PollModel } from "../services/models/poll";


interface PollTagProps {
    pollTag: PollTagModel,
    polls: PollModel[],
    pollTemplate: PollTemplateModel,
    onPollTagClicked: (pollTag: PollTagModel) => void,
    onEditPollTagClicked: (pollTag: PollTagModel) => void,
    onDeletePollTagClicked: (pollTag: PollTagModel) => void,
    className?: string,
};

const PollTag = ({ pollTag, polls, pollTemplate, onPollTagClicked, onEditPollTagClicked, onDeletePollTagClicked, className }: PollTagProps) => {
    const {
        date,
    } = pollTag;
    const {
        title
    } = pollTemplate;

    let isFinished = true;
    for(let index=0; index < polls.length; index++) {
        if(polls[index].allowed_sub_users.length > polls[index].finished_sub_users.length){
            isFinished = false;
        }
    }

    return (
        <Card
            className={isFinished ? `${styles.projectCardFinished} ${className}` : `${styles.projectCard} ${className}`}
            onClick={(e) => {
                onPollTagClicked(pollTag);
                e.stopPropagation();
            }}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title + " - " + translateDate(date)}
                    <div>
                        <MdEdit
                            className="ms-2 text-muted ms-rights"
                            onClick={(e) => {
                                onEditPollTagClicked(pollTag);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="ms-2 text-muted ms-right"
                            onClick={(e) => {
                                onDeletePollTagClicked(pollTag);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
            </Card.Body>
        </Card>
    );
};

export default PollTag;