import { useEffect, useState } from "react";
import { Recommendation } from "../../../services/models/recommendation";
import * as RecommendationApi from "../../../services/network/recommendation-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import RecommendationCard from "./RecommendationCard";
import { FaPlus } from "react-icons/fa";
import AddEditRecommendationDialog from "./AddEditRecommendationDialog";

interface SectorsPageViewProps {
    sectorId: string,
}

const RecommendationsPageView = ({sectorId}: SectorsPageViewProps) => {
    const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
    const [recommendationsLoading, setRecommendationsLoading] = useState(true);
    const [showRecommendationsLoadingError, setShowRecommendationsLoadingError] = useState(false);

    const [showRecommendationForm, setShowRecommendationForm] = useState(false);
    const [recommendationToEdit, setRecommendationToEdit] = useState<Recommendation|null>(null);

    useEffect(() => {
        async function loadRecommendations() {
            try {
                setShowRecommendationsLoadingError(false);
                setRecommendationsLoading(true);
                const recommendationsData = (await RecommendationApi.fetchRecommendationsBySector({sector_id: sectorId}));
                setRecommendations(recommendationsData.reverse());
            } catch (error) {
                console.error(error);
                setShowRecommendationsLoadingError(true);
            } finally {
                setRecommendationsLoading(false);
            }
        }
        loadRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function deleteRecommendation(recommendation: Recommendation) {
        try {
            await RecommendationApi.deleteRecommendation(recommendation.id);
            setRecommendations(recommendations.filter(existingRecommendation => existingRecommendation.id !== recommendation.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    const goToComments = (recommendation: Recommendation) => {
        let path = `${recommendation.id}`;
        navigate(path);
    }

    let navigate = useNavigate();

    const SectorsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                recommendations.map(recommendation => (
                    <Col key={recommendation.id}>
                        <RecommendationCard 
                            recommendation={recommendation}
                            onRecommendationClicked={goToComments}
                            onUpdateRecommendationClicked={setRecommendationToEdit}
                            onDeleteRecommendationClicked={deleteRecommendation}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Рекомендации</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowRecommendationForm(true)}>
                <FaPlus />
                Добавить рекомендацию
            </Button>
            {recommendationsLoading && <Spinner animation='border' variant='primary' />}
            {showRecommendationsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!recommendationsLoading && !showRecommendationsLoadingError && 
                <>
                    {
                        recommendations.length > 0
                        ? SectorsGrid
                        : <p>Вы ещё не добавили рекомендации</p>
                    }
                </>
            }
            { showRecommendationForm &&
                <AddEditRecommendationDialog
                    sectorId={sectorId}
                    onDismiss={() => setShowRecommendationForm(false)}
                    onRecommendationSaved={(newRecommendations) => {
                        setRecommendations([newRecommendations, ...recommendations]);
                        setShowRecommendationForm(false);
                    }}
                />
            }
            { recommendationToEdit &&
                <AddEditRecommendationDialog
                    sectorId={sectorId}
                    recommendationToEdit={recommendationToEdit}
                    onDismiss={() => setRecommendationToEdit(null)}
                    onRecommendationSaved={(updatedRecommendations) => {
                        setRecommendations(recommendations.map(existingRecommendations => existingRecommendations.id === updatedRecommendations.id ? updatedRecommendations : existingRecommendations));
                        setRecommendationToEdit(null);
                        }
                    }
                />
            }
        </>
    );
};

export default RecommendationsPageView;