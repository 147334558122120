import { Button, Card } from "react-bootstrap";
import { Poll as PollModel } from "../services/models/poll";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";
import { SubUser } from "../services/models/sub_user";

interface PollForSubUserProps {
    poll: PollModel,
    user: SubUser,
    sectorName: string,
    tagName: string,
    onFillPollClicked: (poll: PollModel) => void,
    onFinishPollClicked: (poll: PollModel) => void,
    className?: string,
};

const PollForSubUser = ({ poll, user, sectorName, tagName, onFillPollClicked, onFinishPollClicked, className }: PollForSubUserProps) => {
    const {
        title
    } = poll;
    return (
        <Card
            className={`${styles.projectCard} ${className}`}
            onClick={() => onFillPollClicked(poll)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title + " - " + sectorName + " - " + tagName}
                </Card.Title>
            </Card.Body>
            <Card.Footer className={styles.pollButtons}>
                {poll.finished_sub_users.findIndex(finished_sub_user => finished_sub_user===user.id) > -1 ? 
                    <>Отправлено
                    </>
                    :
                    <Button
                        onClick={(e) => {
                            onFinishPollClicked(poll);
                            e.stopPropagation();
                            }}>
                        Отправить
                    </Button>
                }
                
            </Card.Footer>
        </Card>
    );
};

export default PollForSubUser;