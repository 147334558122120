import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import styleUtils from "../../../assets/styles/utils.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { RecommendationComment } from "../../../services/models/recommendation_comment";
import { SubUser } from "../../../services/models/sub_user";
import { Administrator } from "../../../services/models/administrator";

interface CommentCardProps {
    user: SubUser | Administrator,
    comment: RecommendationComment,
    onUpdateCommentClicked: (comment: RecommendationComment) => void,
    onDeleteCommentClicked: (comment: RecommendationComment) => void,
    className?: string,
}

const CommentCard = ({user, comment, onUpdateCommentClicked, onDeleteCommentClicked, className}: CommentCardProps) => {
    const { text, created_at, owner } = comment;
    return(
        <div className={owner === user.id ? 
            `${styles.commentOwner} ${className}`:
            `${styles.comment} ${className}`}>
        <Card className={owner === user.id ? 
            `${styles.commentCardOwner} ${className}`:
            `${styles.commentCard} ${className}`}>
            {owner === user.id &&
                <Card.Title className={`mb-0 ${styleUtils.flexRight}`}>
                    <div className={`mt-1`}>
                        <MdEdit
                            className="ms-1 text-muted ms-rights"
                            onClick={(e) => {
                                onUpdateCommentClicked(comment);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="ms-1 text-muted ms-right"
                            onClick={(e) => {
                                onDeleteCommentClicked(comment);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
            }
        <Card.Body className={styles.cardText}>
            <Card.Text>
                {text}
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            {new Date(created_at).toLocaleString('ru-RU', {minute:"numeric", hour:"numeric", day: "numeric", month: 'numeric' , year: 'numeric'})}
        </Card.Footer>
    </Card>
    </div>
    );
}

export default CommentCard