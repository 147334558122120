import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollTagsPageView from "./PollTagsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const PollTagsPage = () => {
    const {pollTemplateId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {pollTemplateId ?
                    <PollTagsPageView pollTemplateId={pollTemplateId}/> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default PollTagsPage;