import { useEffect, useState } from "react";
import { Sector } from "../../../services/models/sector";
import * as SectorApi from "../../../services/network/sector-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import SectorCard from "./SectorCard";
import { FaPlus } from "react-icons/fa";
import AddEditSectorDialog from "./AddEditSectorDialog";

interface SectorsPageViewProps {
    districtId: string,
}

const SectorsPageView = ({districtId}: SectorsPageViewProps) => {
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [sectorsLoading, setSectorsLoading] = useState(true);
    const [showSectorsLoadingError, setShowSectorsLoadingError] = useState(false);

    const [showSectorForm, setShowSectorForm] = useState(false);
    const [sectorToEdit, setSectorToEdit] = useState<Sector|null>(null);

    useEffect(() => {
        async function loadSectors() {
            try {
                setShowSectorsLoadingError(false);
                setSectorsLoading(true);
                const sectorsData = await SectorApi.fetchSectorsByDistrict({district_id: districtId});
                setSectors(sectorsData);
            } catch (error) {
                console.error(error);
                setShowSectorsLoadingError(true);
            } finally {
                setSectorsLoading(false);
            }
        }
        loadSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function deleteSector(sector: Sector) {
        try {
            await SectorApi.deleteSector(sector.id);
            setSectors(sectors.filter(existingSector => existingSector.id !== sector.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    let navigate = useNavigate();

    const SectorsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                sectors.sort((a, b) => a.name.localeCompare(b.name)).map(sector => (
                    <Col key={sector.id}>
                        <SectorCard 
                            sector={sector}
                            onUpdateSectorClicked={setSectorToEdit}
                            onDeleteSectorClicked={deleteSector}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Сектора</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowSectorForm(true)}>
                <FaPlus />
                Добавить сектор
            </Button>
            {sectorsLoading && <Spinner animation='border' variant='primary' />}
            {showSectorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!sectorsLoading && !showSectorsLoadingError && 
                <>
                    {
                        sectors.length > 0
                        ? SectorsGrid
                        : <p>Вы ещё не добавили сектора</p>
                    }
                </>
            }
            { showSectorForm &&
                <AddEditSectorDialog
                    districtId={districtId}
                    onDismiss={() => setShowSectorForm(false)}
                    onSectorSaved={(newSector) => {
                        console.log(newSector)
                        setSectors([...sectors, newSector]);
                        setShowSectorForm(false);
                    }}
                />
                }
                { sectorToEdit &&
                <AddEditSectorDialog
                    districtId={districtId}
                    sectorToEdit={sectorToEdit}
                    onDismiss={() => setSectorToEdit(null)}
                    onSectorSaved={(updatedSector) => {
                        setSectors(sectors.map(existingSector => existingSector.id === updatedSector.id ? updatedSector : existingSector));
                        setSectorToEdit(null);
                        }
                    }
                />
                }
        </>
    );
};

export default SectorsPageView;