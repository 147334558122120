import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import RecommendationsPageView from "./RecommendationsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const RecommendationsPage = () => {
    const {sectorId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {sectorId ?
                    <RecommendationsPageView sectorId={sectorId}/> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default RecommendationsPage;