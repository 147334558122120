import { useEffect, useState } from "react";
import { Recommendation } from "../../../services/models/recommendation";
import * as RecommendationApi from "../../../services/network/recommendation-api";
import * as CommentsApi from "../../../services/network/recommendation_comment-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import * as SectorApi from "../../../services/network/sector-api";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import CommentCard from "./CommentCard";
import AddCommentDialog from "./AddCommentDialog";
import { RecommendationComment } from "../../../services/models/recommendation_comment";
import EditCommentDialog from "./EditCommentDialog";
import { SubUser } from "../../../services/models/sub_user";
import { Administrator } from "../../../services/models/administrator";
import { Sector } from "../../../services/models/sector";

interface RecommendationInfoPageViewProps {
    user: SubUser | Administrator
    recommendationId: string,
}

const RecommendationInfoPageView = ({ user, recommendationId}: RecommendationInfoPageViewProps) => {
    const [recommendation, setRecommendation] = useState<Recommendation>();
    const [subUser, setSubUser] = useState<SubUser>();
    const [sector, setSector] = useState<Sector>();
    const [comments, setComments] = useState<RecommendationComment[]>([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [showDataLoadingError, setShowDataLoadingError] = useState(false);

    const [commentToEdit, setCommentToEdit] = useState<RecommendationComment|null>(null);

    useEffect(() => {
        async function loadRecommendations() {
            try {
                setShowDataLoadingError(false);
                setDataLoading(true);
                const recommendationData = await RecommendationApi.fetchRecommendation(recommendationId);
                const commentsData = await CommentsApi.fetchRecommendationCommentsByRecommendation({recommendation_id: recommendationId});
                const subUserData = await SubUserApi.getSubUser(recommendationData.in_charge);
                const sectorData = await SectorApi.fetchSector(recommendationData.sector);
                setRecommendation(recommendationData);
                setComments(commentsData.reverse());
                setSubUser(subUserData);
                setSector(sectorData);
            } catch (error) {
                console.error(error);
                setShowDataLoadingError(true);
            } finally {
                setDataLoading(false);
            }
        }
        loadRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function deleteComment(comment: RecommendationComment) {
        try {
            await CommentsApi.deleteRecommendationComment(comment.id);
            setComments(comments.filter(existingComment => existingComment.id !== comment.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    async function changeStatus(status: string) {
        try {
            const updatedRecommendation = await RecommendationApi.updateRecommendationStatus(recommendationId, {new_status: status});
            setRecommendation(updatedRecommendation);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    let navigate = useNavigate();

    const commentsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                comments.map(comment => (
                    <Col key={comment.id}>
                        <CommentCard 
                            user={user}
                            comment={comment}
                            onUpdateCommentClicked={setCommentToEdit}
                            onDeleteCommentClicked={deleteComment}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1 className="mb-4">{recommendation?.name}</h1>
            {dataLoading && <Spinner animation='border' variant='primary' />}
            {showDataLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!dataLoading && !showDataLoadingError && 
                <>
                    <div className={`g-4 ${styles.projectsGrid}`}>
                    <Card className="mb-2">
                        <Card.Body>
                            <div className={styles.curatorNameField}>
                                <div>Статус:</div>
                                {recommendation?.status}
                            </div>
                        </Card.Body>
                        {recommendation?.status === "Оценивается" &&
                            <Card.Footer className={stylesUtils.flexCenterSpaceBetween}>
                                <Button onClick={() => changeStatus("Выполнено")}>Подтвердить</Button>
                                <Button variant="danger" onClick={() => changeStatus("Выполняется")}>Отказать</Button>
                            </Card.Footer>
                        }
                    </Card>
                    <Card className="mb-2">
                        <Card.Body>
                            <div className={styles.curatorNameField}>
                                <div>Регион:</div>
                                {sector?.name}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Body>
                            <div className={styles.curatorNameField}>
                                <div>Ответственный:</div>
                                {subUser?.full_name}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Body>
                            <div className={styles.curatorNameField}>
                                <div>Срок:</div>
                                {recommendation?.deadline}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Body className={stylesUtils.textarea}>
                            <Card.Title>Описание</Card.Title>
                            <Card.Text>
                                {recommendation?.text}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Body>
                            <Card.Title>Целевой показатель</Card.Title>
                            <Card.Text>
                                {recommendation?.goal}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </div>
                    <AddCommentDialog 
                        recommendationId={recommendationId}
                        onCommentSaved={(newComment) => {
                            setComments([newComment, ...comments]);
                        }}
                    />
                    {
                        comments.length > 0
                        ? commentsGrid
                        : <p>Вы ещё не добавили коментарии</p>
                    }
                </>
            }
            { commentToEdit &&
                <EditCommentDialog
                    commentToEdit={commentToEdit}
                    onDismiss={() => setCommentToEdit(null)}
                    onCommentSaved={(updatedComment) => {
                        setComments(comments.map(existingComment => existingComment.id === updatedComment.id ? updatedComment : existingComment));
                        setCommentToEdit(null);
                        }
                    }
                />
            }
        </>
    );
};

export default RecommendationInfoPageView;