import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import RecommendationSectorsPageView from "./RecommendationSectorsPageView";


const RecommendationSectorsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <RecommendationSectorsPageView/>
            </>
        </Container>
    );
};

export default RecommendationSectorsPage;