import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import DistrictsPageView from "./DistrictsPageView";

const DistrictsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <DistrictsPageView/>
            </>
        </Container>
    );
};

export default DistrictsPage;