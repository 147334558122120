import { useEffect, useState } from "react";
import { District } from "../../../services/models/district";
import * as DistrictApi from "../../../services/network/district-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import DistrictCard from "./DistrictCard";
import { FaPlus } from "react-icons/fa";
import AddEditDistrictDialog from "./AddEditDistrictDialog";


const DistrictsPageView = () => {
    const [districts, setDistricts] = useState<District[]>([]);
    const [districtsLoading, setDistrictsLoading] = useState(true);
    const [showDistrictsLoadingError, setShowDistrictsLoadingError] = useState(false);

    const [showDistrictForm, setShowDistrictForm] = useState(false);
    const [districtToEdit, setDistrictToEdit] = useState<District|null>(null);

    useEffect(() => {
        async function loadDistricts() {
            try {
                setShowDistrictsLoadingError(false);
                setDistrictsLoading(true);
                const districtsData = await DistrictApi.fetchDistricts();
                setDistricts(districtsData);
            } catch (error) {
                console.error(error);
                setShowDistrictsLoadingError(true);
            } finally {
                setDistrictsLoading(false);
            }
        }
        loadDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function deleteDistrict(district: District) {
        try {
            await DistrictApi.deleteDistrict(district.id);
            setDistricts(districts.filter(existingDistrict => existingDistrict.id !== district.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    let navigate = useNavigate();

    const goToDistrict = (district: District) => {
        let path = `${district.id}`;
        navigate(path);
    }

    const districtsGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {
                districts.sort((a, b) => a.name.localeCompare(b.name)).map(district => (
                    <Col key={district.id}>
                        <DistrictCard 
                            district={district}
                            onDistrictClicked={goToDistrict}
                            onUpdateDistrictClicked={setDistrictToEdit}
                            onDeleteDistrictClicked={deleteDistrict}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Округи</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowDistrictForm(true)}>
                <FaPlus />
                Добавить округ
            </Button>
            {districtsLoading && <Spinner animation='border' variant='primary' />}
            {showDistrictsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!districtsLoading && !showDistrictsLoadingError && 
                <>
                    {
                        districts.length > 0
                        ? districtsGrid
                        : <p>Вы ещё не добавили округи</p>
                    }
                </>
            }
            { showDistrictForm &&
                <AddEditDistrictDialog
                    onDismiss={() => setShowDistrictForm(false)}
                    onDistrictSaved={(newDistrict) => {
                        setDistricts([...districts, newDistrict]);
                        setShowDistrictForm(false);
                    }}
                />
                }
                { districtToEdit &&
                <AddEditDistrictDialog
                    districtToEdit={districtToEdit}
                    onDismiss={() => setDistrictToEdit(null)}
                    onDistrictSaved={(updatedDistrict) => {
                        setDistricts(districts.map(existingDistrict => existingDistrict.id === updatedDistrict.id ? updatedDistrict : existingDistrict));
                        setDistrictToEdit(null);
                        }
                    }
                />
            }
        </>
    );
};

export default DistrictsPageView;