import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import RecommendationsPageView from "./RecommendationsPageView";

const SubUserRecommendationsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <RecommendationsPageView/>
            </>
        </Container>
    );
};

export default SubUserRecommendationsPage;