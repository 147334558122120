import { FormEvent, useEffect, useRef, useState } from "react";
import styles from "../../../assets/styles/ProjectInfoPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import * as PollApi from "../../../services/network/poll-api";
import React from "react";

interface FillPollPageViewProps {
    pollId: string,
}

const FillPollPageView = ({pollId}: FillPollPageViewProps) => {
    const [poll, setPoll] = useState<PollModel>({
        id: "",
        sector:"",
        tag:"",
        finished_sub_users: [],
        allowed_sub_users: [],
        title: "",
        description: "",
        modules: [
            {
                id: "",
                allowed_sub_users:[],
                title: "",
                description: "",
                values: [
                    {
                        id: "",
                        allowed_sub_users: [],
                        title: "",
                        value: 0.
                    },
                ]
            },
        ],
    });
    const [pollLoading, setPollLoading] = useState(true);
    const [showPollLoadingError, setShowPollLoadingError] = useState(false);

    const inputRefs = useRef<any>({});

    const refocusInput = (name: any) => {
        if (inputRefs.current[name]) {
          inputRefs.current[name].current.focus();
          console.log(name)
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent, moduleIndex: number, valueIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            const nextIndex = valueIndex + 1;
            if (nextIndex < poll.modules[moduleIndex].values.length) {
                refocusInput(`value-value-${moduleIndex.toString()}-${nextIndex.toString()}`); // Focus on next input
            }
        }
    };

    let navigate = useNavigate();
    let location = useLocation();
    
    useEffect(() => {
        async function loadPoll() {
            try {
                setShowPollLoadingError(false);
                setPollLoading(true);
                const pollData = await PollApi.fetchPoll({pollId: pollId});
                setPoll(pollData);
                for (let i = 0; i < pollData.modules.length; i++){
                    for(let j = 0; j < pollData.modules[i].values.length; j++){
                        inputRefs.current[`value-value-${i.toString()}-${j.toString()}`] = React.createRef();
                    }
                }
            } catch (error) {
                console.error(error);
                setShowPollLoadingError(true);
            } finally {
                setPollLoading(false);
            }
        }
        loadPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleValueChange = (moduleIndex: number, valueIndex: number , value: number) => {
        try {
            const updatedModules = poll.modules;
            updatedModules[moduleIndex].values[valueIndex].value = value;
            setPoll({ ...poll, modules: updatedModules });
        } catch (error) {
            console.log(error)
        }
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        try {
            let pollResponse: PollModel;
            pollResponse = await PollApi.updatePoll(pollId, poll);
            setPoll(pollResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            navigate(new URL(".", window.origin + location.pathname).pathname + "result");
        }
    }

    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollLoading && <Spinner animation='border' variant='primary' />}
            {showPollLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollLoading && !showPollLoadingError && 
                <>
                    <h1 className="mb-3">
                        {poll?.title}
                    </h1>
                    <Form onSubmit={handleSubmit}>
                    {[...poll.modules].map((module, moduleIndex)=> (
                    <Card className={`mb-3 ${stylesUtils.flexTextCard}`} key={moduleIndex}>
                        <Card.Header>{module.title}</Card.Header>
                        <Card.Body>
                            {module.values.map((value, valueIndex) => (
                                <Form.Group 
                                    key={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`} 
                                    controlId={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`}
                                    className={styles.valueRow}
                                    >
                                    <div>{value.title}</div>
                                    <Form.Control
                                        key={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`}
                                        className={styles.valueInput}
                                        type={"number"}
                                        step={0.1}
                                        value={value.value}
                                        ref={inputRefs.current[`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`]}
                                        onKeyDown={(e) => handleKeyDown(e, moduleIndex, valueIndex)}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            handleValueChange(moduleIndex, valueIndex, Number(event.target.value));
                                        }}
                                    />
                                </Form.Group>
                                )
                            )
                            }
                        </Card.Body>
                    </Card>
                    )
                    )
                }
                    <Button className="mb-1" type="submit">
                        Сохранить
                    </Button>
                </Form>
                </>
            }
        </>
    );
};

export default FillPollPageView;