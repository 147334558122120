// DeletePollModal.js
import { Modal, Button } from "react-bootstrap";

interface DeleteWarningModalProps {
    onDismiss: () => void;
    text: string;
    onDelete: () => void;
}

const DeleteWarningModal = ({onDismiss, text, onDelete,}: DeleteWarningModalProps) => {
    return (
        <Modal show onHide={onDismiss} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Предупреждение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                 <Button variant="danger" onClick={onDelete}>
                    Удалить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteWarningModal;
