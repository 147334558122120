import { useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { SubUser } from "../../../services/models/sub_user";

interface SelectSubUserFieldProps {
    name: string,
    label: string,
    register: UseFormRegister<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    [x: string]: any,
}

const SelectSubUserField = ({name, label, register, registerOptions, error, ...props}: SelectSubUserFieldProps) => {
    const [subUser, setSubUser] = useState<SubUser|null>(null)
    const [subUsersToShow, setSubUsersToShow] = useState<SubUser[]>(props.items);
    const [subUsersNameFilter, setSubUsersNameFilter] = useState("");
    
    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = props.items.filter((user: any) => {
                return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setSubUsersToShow(filteredArray);
        } else {
            setSubUsersToShow(props.items);
        }

        setSubUsersNameFilter(keyword);
    }
    return (
        <Form.Group className="mb-3" controlId={name + "-input"}>
            <Form.Label>{label}</Form.Label>
            <DropdownButton
                id={`allowed-subUser-dropdown`}
                title={subUser ? subUser.full_name : "Выбрать специалиста"}
                autoClose
            >
                <input
                    type="search"
                    value={subUsersNameFilter}
                    onChange={filter}
                    placeholder="Поиск по ФИО">
                </input>
                {subUsersToShow.sort((a: any, b: any) => a.full_name.localeCompare(b.full_name)).map((item: any) =>
                <Dropdown.Item
                    key={item.id}
                    onClick={() => {
                        setSubUser(item);
                        props.setValue(name, item.id);
                    }}
                >
                    {item.full_name}
                </Dropdown.Item>
                )}
            </DropdownButton>
            <Form.Control.Feedback type="invalid">
                {error?.message}
            </Form.Control.Feedback>
            
        </Form.Group>
    );
}

export default SelectSubUserField;