import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollStatisticsPageView from "./SectorStatisticsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const SectorStatisticsPage = () => {
    const {pollTemplateId, districtId} = useParams();
    return (
        <Container className={styles.projectPage}>
            {pollTemplateId && districtId ?
                <PollStatisticsPageView
                    pollTemplateId={pollTemplateId}
                    districtId={districtId}
                /> :
                <NotFoundPage/>
            }
        </Container>
    );
};

export default SectorStatisticsPage;