import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import * as CommentApi from "../../../services/network/recommendation_comment-api";
import { RecommendationComment } from "../../../services/models/recommendation_comment";

interface EditCommentDialogProps {
    commentToEdit: RecommendationComment,
    onDismiss: () => void,
    onCommentSaved: (comment: RecommendationComment) => void,
}

const EditCommentDialog = ({commentToEdit, onDismiss, onCommentSaved}: EditCommentDialogProps) => {
    interface RecommendationFormInput{
        recommendation: string,
        text: string,
    }

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<RecommendationFormInput>({
        defaultValues: {
            recommendation: commentToEdit.recommendation,
            text: commentToEdit.text,
        }
    });

    async function onSubmit(input: RecommendationFormInput) {
        try {
            let commentResponse: RecommendationComment;
            commentResponse = await CommentApi.updateRecommendationComment(commentToEdit.id, input);
            onCommentSaved(commentResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Редактировать комментарий
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Form id="editCommentForm" onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            name="text"
                            label="Комментарий"
                            as="textarea"
                            placeholder="Описание"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.text}
                        />
                    </Form>
                </Modal.Body>
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="editCommentForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditCommentDialog;