import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import styleUtils from "../../../assets/styles/utils.module.css";
import { Sector } from "../../../services/models/sector";
import { Recommendation } from "../../../services/models/recommendation";

interface RecommendationSectorCardProps {
    sector: Sector,
    recommendations: Recommendation[],
    onSectorClicked: (sector: Sector) => void,
    className?: string,
}

const RecommendationSectorCard = ({sector, recommendations, onSectorClicked, className}: RecommendationSectorCardProps) => {
    const { name } = sector;
    const recommendationsToCheck = recommendations.filter(recommendation => recommendation.status === "Оценивается").length;
    const recommendationsInProgress = recommendations.filter(recommendation => recommendation.status === "Выполняется").length;
    const recommendationsDone = recommendations.filter(recommendation => recommendation.status === "Выполнено").length;
    return(
        <Card
        className={`${styles.projectCard} ${className}`}
        onClick={(e) => {
            e.preventDefault()
            onSectorClicked(sector)
        }}
        >
        <Card.Header className={styles.cardBody}>
            <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                {name}
            </Card.Title>
        </Card.Header>
        <Card.Body className={styleUtils.cardLeft}>
            <Card.Text>
                {"Требует оценивания: " + recommendationsToCheck}
            </Card.Text>
            <Card.Text>
                {"В процессе: " + recommendationsInProgress}
            </Card.Text>
            <Card.Text>
                {"Выполнено: " + recommendationsDone}
            </Card.Text>
        </Card.Body>
    </Card>
    );
}

export default RecommendationSectorCard