import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import RecommendationInfoPageView from "./RecommendationInfoPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";
import { SubUser } from "../../../services/models/sub_user";
import { Administrator } from "../../../services/models/administrator";

interface RecommendationInfoPageProps{
    user: SubUser| Administrator | null
}

const RecommendationInfoPage = ({user}: RecommendationInfoPageProps) => {
    const {sectorId, recommendationId} = useParams();
    if (!user){
        return(<NotFoundPage/>)
    }
    return (
        <Container className={styles.projectPage}>
            <>
                {sectorId && recommendationId ?
                    <RecommendationInfoPageView user={user} recommendationId={recommendationId}/> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default RecommendationInfoPage;