import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollStatisticsPageView from "./DistrictStatisticsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const DistrictStatisticsPage = () => {
    const {pollTemplateId} = useParams();
    return (
        <Container className={styles.projectPage}>
            {pollTemplateId ?
                <PollStatisticsPageView
                    pollTemplateId={pollTemplateId}
                /> :
                <NotFoundPage/>
            }
        </Container>
    );
};

export default DistrictStatisticsPage;