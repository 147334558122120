import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { District } from "../../../services/models/district";
import * as DistrictApi from "../../../services/network/district-api";

interface AddEditDistrictDialogProps {
    districtToEdit?: District,
    onDismiss: () => void,
    onDistrictSaved: (district: District) => void,
}

const AddEditDistrictDialog = ({districtToEdit, onDismiss, onDistrictSaved}: AddEditDistrictDialogProps) => {
    interface DistrictFormInput{
        name: string,
    }

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<DistrictFormInput>({
        defaultValues: {
            name: districtToEdit?.name || "",
        }
    });

    async function onSubmit(input: DistrictFormInput) {
        try {
            let districtResponse: District;
            if (districtToEdit) {
                districtResponse = await DistrictApi.updateDistrict(districtToEdit.id, input);
            } else {
                districtResponse = await DistrictApi.createDistrict(input);
            }
            onDistrictSaved(districtResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {districtToEdit ? "Редактировать округ" : "Добавить округ"}
                </Modal.Title>
            </Modal.Header> 
            <Modal.Body>
                <Form id="addEditDistrictForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="name"
                        label="Название"
                        type="text"
                        placeholder="Округ"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.name}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="addEditDistrictForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditDistrictDialog;