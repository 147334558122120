import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { Recommendation } from "../../../services/models/recommendation";
import * as RecommendationApi from "../../../services/network/recommendation-api";
import { useEffect, useState } from "react";
import { SubUser } from "../../../services/models/sub_user";
import * as SubUserApi from "../../../services/network/sub_user-api";
import SelectSubUserField from "../../../components/AdminComponents/form/SelectSubUserField";

interface AddEditRecommendationDialogProps {
    sectorId: string,
    recommendationToEdit?: Recommendation,
    onDismiss: () => void,
    onRecommendationSaved: (recommendation: Recommendation) => void,
}

const AddEditRecommendationDialog = ({sectorId, recommendationToEdit, onDismiss, onRecommendationSaved}: AddEditRecommendationDialogProps) => {
    const [subUsers, setSubUsers] = useState<SubUser[]>([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [showSubUsersLoadingError, setShowSubUsersLoadingError] = useState(false);

    useEffect(() => {
        async function loadSubUsers() {
            try {
                setShowSubUsersLoadingError(false);
                setDataLoading(true);
                const subUsersData = await SubUserApi.getSubUsers();
                setSubUsers(subUsersData.filter(subUser => subUser.sectors.findIndex(sector => sector === sectorId) > -1));
            } catch (error) {
                console.error(error);
                setShowSubUsersLoadingError(true);
            } finally {
                setDataLoading(false);
            }
        }
        loadSubUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    interface RecommendationFormInput{
        sector: string,
        name: string,
        text: string,
        in_charge: string,
        deadline: string,
        goal: string,
        status: string,
    }

    const { register, handleSubmit, formState: {errors, isSubmitting}, setValue} = useForm<RecommendationFormInput>({
        defaultValues: {
            sector: recommendationToEdit?.sector || sectorId,
            name: recommendationToEdit?.name || "",
            text: recommendationToEdit?.text || "",
            in_charge: recommendationToEdit?.in_charge || "",
            deadline: recommendationToEdit?.deadline || "",
            goal: recommendationToEdit?.goal || "",
            status: recommendationToEdit?.status || "Выполняется",
        }
    });

    async function onSubmit(input: RecommendationFormInput) {
        try {
            let districtResponse: Recommendation;
            if (recommendationToEdit) {
                districtResponse = await RecommendationApi.updateRecommendation(recommendationToEdit.id, input);
            } else {
                districtResponse = await RecommendationApi.createRecommendation(input);
            }
            onRecommendationSaved(districtResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {recommendationToEdit ? "Редактировать рекомендацию" : "Добавить рекомендацию"}
                </Modal.Title>
            </Modal.Header>
            {dataLoading && <Spinner animation='border' variant='primary' />}
            {showSubUsersLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!dataLoading && !showSubUsersLoadingError && 
                <Modal.Body>
                    <Form id="addEditRecommendationForm" onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            name="name"
                            label="Название"
                            type="text"
                            placeholder="Название"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.name}
                        />
                        <TextInputField
                            name="text"
                            label="Рекомендация"
                            as="textarea"
                            placeholder="Описание"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.text}
                        />
                        <SelectSubUserField
                            name="in_charge"
                            label="Ответсвенный"
                            items={subUsers}
                            register={register}
                            registerOptions={{required: "Required"}}
                            setValue={setValue}
                            error={errors.in_charge}
                        />
                        <TextInputField
                            name="deadline"
                            label="Срок"
                            type="text"
                            placeholder="Дата"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.deadline}
                        />
                        <TextInputField
                            name="goal"
                            label="Целевой показатель"
                            type="text"
                            placeholder="Цел. показатель"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.goal}
                        />
                    </Form>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="addEditRecommendationForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditRecommendationDialog;