import { Button, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sector as SectorModel } from "../../../services/models/sector";
import createPolls from "../../../Utils/createPolls";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { SubUser as SubUserModel } from "../../../services/models/sub_user";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";

interface AddPollsPageViewProps {
    pollTemplateId: string,
    pollTagId: string
}

const AddPollsPageView = ({pollTemplateId, pollTagId}: AddPollsPageViewProps) => {
    const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>();
    const [subUsers, setSubUsers] = useState<SubUserModel[]>([]);
    const [sectors, setSectors] = useState<SectorModel[]>([]);
    const [sectorsToShow, setSectorsToShow] = useState<SectorModel[]>([]);
    const [sectorIdsToUse, setSectorIdsToUse] = useState<string[]>([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [showDataLoadingError, setShowDataLoadingError] = useState(false);
    
    const [sectorNameFilter, setSectorsNameFilter] = useState("");

    useEffect(() => {
        async function loadPolls() {
            try {
                setShowDataLoadingError(false);
                setDataLoading(true);
                const pollTemplateData = await PollTemplateApi.fetchPollTemplate({pollTemplateId});
                const subUsersData = await SubUserApi.getSubUsers();
                const pollsData = await PollApi.fetchPolls({tag_id: pollTagId});
                const sectorsData = await SectorApi.fetchSectors();
                for (let pollIndex=0; pollIndex < pollsData.length; pollIndex++) {
                    const sectorIndex = sectorsData.findIndex(sector => {return sector.id === pollsData[pollIndex].sector});
                    if (sectorIndex > -1) {
                        sectorsData.splice(sectorIndex, 1);
                    }
                }
                setSectors(sectorsData);
                setSectorsToShow(sectorsData);
                setPollTemplate(pollTemplateData);
                setSubUsers(subUsersData);
            } catch (error) {
                console.error(error);
                setShowDataLoadingError(true);
            } finally {
                setDataLoading(false);
            }
        };
        loadPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let navigate = useNavigate();

    const filterSectors = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = sectors.filter((sector) => {
                return sector.name.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setSectorsToShow(filteredArray);
        } else {
            setSectorsToShow(sectors);
        }

        setSectorsNameFilter(keyword);
    }

    const handleSectorIdsToUseChange = (sectorId: string) => {
        let sectorIds = sectorIdsToUse;
        const index = sectorIds.indexOf(sectorId, 0);
        if (index > -1) {
            sectorIds.splice(index, 1);
        } else {
            sectorIds.push(sectorId);
        }
        setSectorsToShow([...sectorsToShow]);
        setSectorIdsToUse(sectorIds);
    };

    const handleSectorIdsToUseAllAdd = (sectors: SectorModel[]) => {
        let sectorIds = sectorIdsToUse;
        let deleteSectors = true;
        for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++){
            const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
            if (!(index > -1)) {
                deleteSectors = false;
                sectorIds.push(sectors[sectorIndex].id);
            }
        }
        if (deleteSectors) {
            for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++){
                const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
                if (index > -1) {
                    sectorIds.splice(index, 1);
                }
            }
        }
        setSectorsToShow([...sectorsToShow]);
        setSectorIdsToUse(sectorIds);
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        try {
            if (pollTemplate) createPolls({sectorIdsToUse, pollTemplate, subUsers, pollTagId});
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            navigate(-1);
        }
    }

    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Добавление секторов</h1>
            {dataLoading && <Spinner animation='border' variant='primary' />}
            {showDataLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!dataLoading && !showDataLoadingError && 
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2" controlId={`sectors`}>
                        <DropdownButton
                            id={`sectors-dropdown`}
                            title="Выбрать сектора"
                            autoClose="outside"
                        >
                            <input
                                type="search"
                                value={sectorNameFilter}
                                onChange={filterSectors}
                                className={styles.search}
                                placeholder="Поиск по названию">
                            </input>
                            <Button onClick={() => handleSectorIdsToUseAllAdd(sectorsToShow)}>Выбрать все</Button>
                            {sectorsToShow.map((sector) => (
                            <Dropdown.Item
                                key={sector.id}
                                onClick={() => handleSectorIdsToUseChange(sector.id)}
                                className={styles.subUserItem}
                                >
                                {sector.name}
                                {sectorIdsToUse.includes(sector.id) 
                                ?  <FaRegCheckCircle/>
                                : <FaRegCircle/>
                                }
                            </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Form.Group>
                    <div className={stylesUtils.flexCenter}>
                        <Button type="submit">Сохранить</Button>
                    </div>
                </Form>
            }
        </>
    );
};

export default AddPollsPageView;