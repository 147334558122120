import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import styleUtils from "../../../assets/styles/utils.module.css";
import { Sector } from "../../../services/models/sector";
import { MdDelete, MdEdit } from "react-icons/md";

interface SectorCardProps {
    sector: Sector,
    onUpdateSectorClicked: (sector: Sector) => void,
    onDeleteSectorClicked: (sector: Sector) => void,
    className?: string,
}

const SectorCard = ({sector, onUpdateSectorClicked, onDeleteSectorClicked, className}: SectorCardProps) => {
    const { name } = sector;
    return(
        <Card
        className={`${styles.projectCard} ${className}`}
        >
        <Card.Body className={styles.cardBody}>
            <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                {name}
                <div>
                    <MdEdit
                        className="ms-2 text-muted ms-rights"
                        onClick={(e) => {
                            onUpdateSectorClicked(sector);
                            e.stopPropagation();
                        }}
                    />
                    <MdDelete
                        className="ms-2 text-muted ms-right"
                        onClick={(e) => {
                            onDeleteSectorClicked(sector);
                            e.stopPropagation();
                        }}
                    />
                </div>
            </Card.Title>
        </Card.Body>
    </Card>
    );
}

export default SectorCard