import { District } from "../models/district";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";



export async function fetchDistricts() : Promise<District[]>{

    const response = await fetchData("https://nmic.testu.online/api/district",
        {
            method: "GET",
            headers: {
                "Authorization": authToken(),
            },
        });
    return response.json();
}

export async function fetchDistrict(districtId: string): Promise<District> {
    const response = await fetchData("https://nmic.testu.online/api/district/"+districtId, 
    {
        method: "GET",
        headers: {
            "Authorization": authToken(),
        },
    });
    return response.json();
}

export interface DistrictInputProps{
    name: string,
}

export async function createDistrict(district: DistrictInputProps): Promise<District> {
    const response = await fetchData("https://nmic.testu.online/api/district",
    {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authToken(),
        },
        body: JSON.stringify(district),
    });
    return response.json();
}

export async function updateDistrict(districtId:string, district: DistrictInputProps): Promise<District> {
    const response = await fetchData("https://nmic.testu.online/api/district/"+districtId,
    {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "Authorization": authToken(),
        },
        body: JSON.stringify(district),
    })
    return response.json();
}

export async function deleteDistrict(districtId:string) {
    await fetchData("https://nmic.testu.online/api/district/"+districtId, 
        { 
            method: "DELETE",
            headers: {
                "Authorization": authToken(),
            },
        });
}
