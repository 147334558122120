import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollsPageView from "./PollsPageView";
import { SubUser } from "../../../services/models/sub_user";
import { Administrator } from "../../../services/models/administrator";
import NotFoundPage from "../../NotFound/NotFoundPage";

interface SubUserPollsPageProps{
    user: SubUser| Administrator | null
}

const SubUserPollsPage = ({user}: SubUserPollsPageProps) => {
    function isSubUser(obj: any): obj is SubUser {
        return obj.role !== undefined 
    }
    if (!user){
        return(<NotFoundPage/>)
    }
    if (isSubUser(user)){
        return (
        <Container className={styles.projectPage}>
            <>
                <PollsPageView user={user}/>
            </>
        </Container>
    );
    }
    return(<NotFoundPage/>)
};

export default SubUserPollsPage;