import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import AddPollsPageView from "./AddPollsPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const AddPollsPage = () => {
    const {pollTemplateId, pollTagId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {(pollTemplateId && pollTagId) ?
                <AddPollsPageView
                    pollTemplateId={pollTemplateId}
                    pollTagId={pollTagId}
                />:
                <NotFoundPage/>}
                
            </>
        </Container>
    );
};

export default AddPollsPage;