import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import CreateUpdatePollTagPageView from "./CreateUpdatePollTagPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

interface CreateUpdatePollTagPageProps {
    createNew: boolean
}

const CreateUpdatePollTagPage = ({createNew}: CreateUpdatePollTagPageProps) => {
    const {pollTemplateId, pollTagId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {pollTemplateId ?
                    <CreateUpdatePollTagPageView
                        pollTemplateId={pollTemplateId}
                        pollTagId={pollTagId}
                        createNew={createNew}
                    /> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default CreateUpdatePollTagPage;