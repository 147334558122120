import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { RecommendationComment } from "../../../services/models/recommendation_comment";
import * as CommentApi from "../../../services/network/recommendation_comment-api";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";


interface AddCommentDialogProps {
    recommendationId: string,
    onCommentSaved: (recommendation: RecommendationComment) => void,
}

const AddCommentDialog = ({recommendationId, onCommentSaved}: AddCommentDialogProps) => {
    interface RecommendationFormInput{
        recommendation: string,
        text: string,
    }

    const { register, handleSubmit, formState: {errors, isSubmitting}, setValue } = useForm<RecommendationFormInput>({
        defaultValues: {
            recommendation: recommendationId,
            text: "",
        }
    });

    async function onSubmit(input: RecommendationFormInput) {
        try {
            let commentResponse: RecommendationComment;
            commentResponse = await CommentApi.createRecommendationComment(input);
            onCommentSaved(commentResponse);
            setValue("text", "");
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <div className={styles.projectsGrid}>
                <Form id="addCommentForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="text"
                        label=""
                        as="textarea"
                        placeholder="Введите комментарий"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.text}
                    />
                </Form>
                <Button 
                    type="submit"
                    form="addCommentForm"
                    disabled={isSubmitting}
                    className="mb-4"
                    >
                    Добавить комментарий
                </Button>
        </div>
    );
}

export default AddCommentDialog;