import { useEffect, useState } from "react";
import { SubUser } from "../../../services/models/sub_user";
import { Role } from "../../../services/models/role";
import * as SubUserApi from "../../../services/network/sub_user-api";
import * as RoleApi from "../../../services/network/role-api";
import * as SectorApi from "../../../services/network/sector-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css"
import { useNavigate } from "react-router-dom";
import SubUserCard from "./SubUserCard";
import { FaPlus } from "react-icons/fa";
import AddEditSubUserDialog from "./AddEditSubUserDialog";
import { Sector } from "../../../services/models/sector";

const SubUserPageView = () => {
    const [subUsers, setSubUsers] = useState<SubUser[]>([]);
    const [subUsersLoading, setSubUsersLoading] = useState(true);
    const [showSubUsersLoadingError, setShowSubUsersLoadingError] = useState(false);

    const [roles, setRoles] = useState<Role[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);

    const [subUsersNameFilter, setSubUsersNameFilter] = useState("");
    const [subUsersToShow, setSubUsersToShow] = useState<SubUser[]>([]);

    const [showSubUserForm, setShowSubUserForm] = useState(false);
    const [subUserToEdit, setSubUserToEdit] = useState<SubUser|null>(null);

    useEffect(() => {
        async function loadSubUsers() {
            try {
                setShowSubUsersLoadingError(false);
                setSubUsersLoading(true);
                const subUsersData = await SubUserApi.getSubUsers();
                const rolesData = await RoleApi.fetchRoles();
                const sectorsData = await SectorApi.fetchSectors();
                setSectors(sectorsData);
                setRoles(rolesData);
                setSubUsers(subUsersData);
                setSubUsersToShow(subUsersData);
            } catch (error) {
                console.error(error);
                setShowSubUsersLoadingError(true);
            } finally {
                setSubUsersLoading(false);
            }
        }
        loadSubUsers();
    }, []);

    async function deleteSubUser(subUser: SubUser) {
        try {
            await SubUserApi.deleteSubUser(subUser.id);
            setSubUsers(subUsers.filter(existingSubUser => existingSubUser.id !== subUser.id));
            setSubUsersToShow(subUsers.filter(existingSubUser => existingSubUser.id !== subUser.id));
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = subUsers.filter((user) => {
                return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setSubUsersToShow(filteredArray);
        } else {
            setSubUsersToShow(subUsers);
        }

        setSubUsersNameFilter(keyword);
    }

    let navigate = useNavigate();

    const SubUsersGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.subUsersGrid}`}>
            {
                subUsersToShow.map(subUser => (
                    <Col key={subUser.id}>
                        <SubUserCard 
                            subUser={subUser} 
                            roles={roles.filter(role => subUser.roles.includes(role.id))}
                            sectors={sectors.filter(sector => subUser.sectors.includes(sector.id))}
                            onUpdateSubUserClicked={setSubUserToEdit}
                            onDeleteSubUserClicked={deleteSubUser}
                        />
                    </Col>
                ))
            }
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Пользователи</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowSubUserForm(true)}>
                <FaPlus />
                Добавить пользователя
            </Button>
            {subUsersLoading && <Spinner animation='border' variant='primary' />}
            {showSubUsersLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!subUsersLoading && !showSubUsersLoadingError && 
                <>
                    <div>
                        <input
                            type="search"
                            value={subUsersNameFilter}
                            onChange={filter}
                            className={styles.search}
                            placeholder="Поиск по ФИО">
                        </input>
                    </div>
                    <>
                        {
                            subUsers.length > 0
                            ? SubUsersGrid
                            : <p>Вы ещё не добавили пользователей</p>
                        }
                    </>
                </>
            }
            { showSubUserForm &&
                <AddEditSubUserDialog
                onDismiss={() => setShowSubUserForm(false)}
                onSubUserSaved={(newSubUser) => {
                    console.log(newSubUser)
                    setSubUsers([...subUsers, newSubUser]);
                    setSubUsersToShow([...subUsers, newSubUser]);
                    setShowSubUserForm(false);
                }}
                />
                }
                { subUserToEdit &&
                <AddEditSubUserDialog
                  subUserToEdit={subUserToEdit}
                    onDismiss={() => setSubUserToEdit(null)}
                    onSubUserSaved={(updatedSubUser) => {
                        setSubUsers(subUsers.map(existingSubUser => existingSubUser.id === updatedSubUser.id ? updatedSubUser : existingSubUser));
                        setSubUsersToShow(subUsers.map(existingSubUser => existingSubUser.id === updatedSubUser.id ? updatedSubUser : existingSubUser));
                        setSubUserToEdit(null);
                        }
                    }
                />
                }
        </>
    );
};

export default SubUserPageView;