import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import UpdatePollPageView from "./UpdatePollPageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";


const UpdatePollPage = () => {
    const { pollId } = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {pollId ? 
                    <UpdatePollPageView
                        pollId={pollId}
                    /> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default UpdatePollPage;